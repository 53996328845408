import { v4 as uuidv4 } from "uuid";

const songData = [
    {
        title: "Love Story",
        artist: "Taylor Swift",
        coverUrl:
            "https://api.audiohut.ackr8.com/Pop/1.png",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Pop/1.png",
        audio: "https://api.audiohut.ackr8.com/Pop/1.mp3",
        palette: "coral",
        id: uuidv4(),
    },
    {
        title: "Taki Taki",
        artist: "Dj Snake",
        coverUrl:
            "https://api.audiohut.ackr8.com/Pop/2.jpeg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Pop/2.jpeg",
        audio: "https://api.audiohut.ackr8.com/Pop/2.mp3",
        palette: "yellow",
        id: uuidv4(),
    },
    {
        title: "Love Me Like You Do",
        artist: "Ellie Goulding",
        coverUrl:
            "https://api.audiohut.ackr8.com/Pop/3.png",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Pop/3.png",
        audio: "https://api.audiohut.ackr8.com/Pop/3.mp3",
        palette: "purple",
        id: uuidv4(),
    },
    {
        title: "Havana",
        artist: "Camila Cabello",
        coverUrl:
            "https://api.audiohut.ackr8.com/Pop/4.png",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Pop/4.png",
        audio: "https://api.audiohut.ackr8.com/Pop/4.mp3",
        palette: "green",
        id: uuidv4(),
    },

    {
        title: "Blank Space",
        artist: "taylor Swift",
        coverUrl:
            "https://api.audiohut.ackr8.com/Pop/5.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Pop/5.jpg",
        audio: "https://api.audiohut.ackr8.com/Pop/5.mp3",
        palette: "purple",
        id: uuidv4(),
    },
];

export default songData;